<template>
  <div class="agency-container">
    <Top></Top>
    <Fix></Fix>
    <div class="agency-container-top">
      <div>
        <div class="agency-div1">
          <img :src="require('/static/images/guolao.png')" alt="">
        </div>
        <div class="agency-div2"></div>
        <div class="agency-div3" @click="toagencyCeter">代理人中心</div>
      </div>
    </div>
    <div class="agency-center">
      <div class="agency-container-middle-box-collapse">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <img class="agency-container-middle-box-collapse-img" :src="require('/static/images/system.png')" alt="">
              <span class="agency-container-middle-box-collapse-span">代理人中心</span>
            </template>
            <router-link class="agency-container-middle-box-collapse-personal" tag="div" to="/agency/wallet" v-if="isAgent">
              我的钱包
            </router-link>
          </el-collapse-item>

        </el-collapse>
      </div>
      <router-view></router-view>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from '../../ui/top.vue';
import Fix from '../../ui/fix.vue';
import Foot from '../../ui/foot.vue';
import {getCookie} from "../../../cookie";

export default {
  name: 'agency',
  components: {
    Top,
    Foot,
    Fix
  },
  data() {
    return {
      activeNames: ['1'],
    };
  },
  computed:{
    isAgent(){
      return getCookie('user_type') === '2'
    }
  },
  mounted() {

    },
  methods: {
    toagencyCeter() {
      this.$router.push('/agency')
    },
    
  }
}

</script>

<style scoped>
.agency-container {
  background-color: #fafafa;
  z-index: 2;
  overflow: hidden;
}

.router-link-active {
  color: #EB5E00 !important;
}

/*.agency-container > div:nth-child(5){*/
/*  min-height: calc(100vh - 508px);*/
/*}*/
.agency-container-top {
  width: 100%;
  box-shadow: 0 4px 4px 0 rgba(231, 231, 231, 0.5);
  height: 96px;
  background: #ffffff;
  margin-bottom: 4px;
}

.agency-container-top > div {
  width: 1000px;
  margin: 0 auto;
  height: 96px;
}

.agency-div1 {
  width: 120px;
  height: 45px;
  margin-top: 18px;
  margin-right: 50px;
}

.agency-div1 > img {
  margin-top: 3px;
}

.agency-div2 {
  width: 2px;
  height: 36px;
  background-color: #EB5E00;
  margin: 30px 18px 0 0;
}

.agency-div3 {
  font-size: 24px;
  margin-top: 31px;
  color: #EB5E00;
  cursor: pointer;
}

.agency-container-top > div > div {
  float: left;
}

.agency-center {
  width: 1160px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
}

.agency-container-middle-box-collapse {
  width: 170px;
  height: calc(100vh - 416px);
  background: #fff;
  margin: 12px 0 0;
}

.agency-center >>> .el-collapse-item__wrap {
  border-color: white;
}

.agency-container-middle-box-collapse-center {
  font-size: 22px;
  font-weight: 100;
  margin: 0 0 14px 65px;
  color: #999999;
}

.agency-container-middle-box-collapse-img {
  margin-right: 11px;
  margin-left: 27px;
  width: 14px;
  height: 14px;
}

.agency-container-middle-box-collapse-span {
  font-size: 14px;
  color: #333333;
  font-weight: 700;
  white-space: nowrap;
}

.agency-container-middle-box-collapse-personal {
  font-size: 14px;
  font-weight: 100;
  margin: 0px 0px 4px 54px;
  color: #666666;
  cursor: pointer;
}
</style>

